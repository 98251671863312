<template>
  <div class="home-wrapper">
    <div class="logo-container"><img :src="logoHomeView" alt="" /></div>
  </div>
</template>

<script>
import back from '../assets/img/back.jpg'
import logoHomeView from '../assets/img/Logo_Start.svg'
export default {
    data() {
        return {
            image: back,
                   logoHomeView
        }
    },
}
</script>

<style>
.home-wrapper {
  display: flex;

  background-image: url("../assets/img/back.jpg");
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  left: 0;
  position: absolute;
}
@media only screen and (min-width: 600px) {
  .home-wrapper {
    display: flex;
    background-image: url("../assets/img/back.jpg");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 60%;
    left: 0;
    position: absolute;
  }
  .home-wrapper img {
    max-width: 100%;
  }
}
.logo-container {
  width: 40%;
  height: 30%;
  text-align: left;
  margin-top: 0;
}
.logo-container img {
  height: 75%;
  margin-top: 5%;
}
</style>