import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AtelierPage from '../components/AtelierPage.vue'
import ÜberMich from '../components/ÜberMich.vue'
import ImPressum from '../components/ImPressum.vue'
import KonTakt from '../components/KonTakt.vue'
import portFolio from '../components/portFolio.vue'
import DatenSchutz from '../components/DatenSchutz.vue'
import AnFahrt from '../components/AnFahrt.vue'
//import TerminBuchen from '../components/TerminBuchen.vue'
import TestForm from '../components/TestForm.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/atelier',
    name: 'atelier',
    component: AtelierPage
  },
  {
    path: '/übermich',
    name: 'übermich',
    component: ÜberMich
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: ImPressum
  },
  {
    path: '/kontakt',
    name: 'kontakt',
    component: KonTakt
  }, {
    path: '/testform',
    name: 'testform',
    component: TestForm
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    component: portFolio
  },
  {
    path: '/datenschutz',
    name: 'datenschutz',
    component: DatenSchutz
  },
  {
    path: '/anfahrt',
    name: 'anfahrt',
    component: AnFahrt
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
