<template>
  <div class="über-mich-wrapper">
    <div class="ueber-mich-box-a">
      Hi! <br />
      Ich bin Theresa
    </div>
    <div class="ueber-mich-box-b"><img :src="theresa" alt="" /></div>
    <div class="ueber-mich-box-c">
      <div class="icon-width">
        <img class="social-icon" :src="igIcon" alt="" />
      </div>
      <div class="icon-width">
        <img class="social-icon" :src="tikIcon" alt="" />
      </div>
    </div>
    <div class="ueber-mich-box-d">
      Mit "gunst&tinte" habe ich mir meinen langersehnten Traum eines eigenen
      Ateliers erfüllt, in dem sehr viel Kunst passiert. Hauptsächlich liebe ich
      es, Tattoos zu stechen und meine Kunden mit dieser wunderbaren Form der
      Körperkunst glücklich zu machen. <br />
      Seit 2018 tätowiere ich hauptberuflich und nun auch selbstständig. Die
      Tattoos, die in diesem Atelier zum Leben erweckt werden, zeichnen sich
      durch Individualität und Detailverliebtheit - ich überrasche euch gerne
      mit meiner Kreativität und dem Anspruch an mich selbst, mit jeder Arbeit
      wieder einmal mehr über mich hinaus zu wachsen. <br />
      Solltest du noch kein Tattoo besitzen, meine Kunst aber trotzdem mögen,
      bist du dennoch herzlich eingeladen. Auch Leinwände, Aquarellpapiere und
      Textilien haben es mir angetan. So hast du die Möglichkeit, mich auch ohne
      das Surren der Tattoomaschine im Hintergrund kennenzulernen, und
      vielleicht entscheidest du dich am Ende ja doch für etwas Schönes, das für
      immer bleibt. <br />
      Ich freue mich auf dich! <br />
      Deine Theresa
    </div>
  </div>
</template>

<script>
import überBg from '../assets/img/Background_red.jpg'
import theresa from '../assets/img/Bild_Theresa.png'
import igIcon from '../assets/img/Icon_Instagram_offwhite.svg'
import tikIcon from '../assets/img/Icon_TikTok_offwhite.svg'

export default {
    data() {
        return {
            image: überBg,
                   theresa,
                   igIcon,
                   tikIcon
        }
    },
}
</script>

<style>
.über-mich-wrapper {
  font-family: monospace;
  font-size: large;
  color: antiquewhite;
  display: flex;
  flex-direction: column;
  background-image: url("../assets/img/Background_red.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  left: 0;
  position: absolute;
}
@media only screen and (min-width: 600px) {
  .über-mich-wrapper {
    font-family: monospace;
    font-size: large;
    color: antiquewhite;
    display: flex;
    flex-direction: column;
    background-image: url("../assets/img/Background_red.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 60%;
    left: 0;
    position: absolute;
  }
  .ueber-mich-box-a {
    font-family: Casta;
    color: antiquewhite;
    font-size: 3em;
    font-weight: lighter;
    height: 20%;
    margin-left: 20%;

    text-align: left;
  }
  .ueber-mich-box-d {
    height: 50%;
    text-align: left;
    margin-left: 18%;
    width: 55%;
    overflow-y: auto;
    margin-bottom: 0%;
  }
}
.ueber-mich-box-a {
  font-family: Casta;
  color: antiquewhite;
  font-size: 3em;
  font-weight: lighter;
  height: 20%;
  margin-top: 10%;
}
.ueber-mich-box-b img {
  max-height: 100%;
  width: 65%;
}
.ueber-mich-box-c {
  margin-left: 36%;
  text-align: left;

  display: flex;
  flex-direction: row;

  width: 20%;
}
.social-icon {
  width: 40%;
}

.ueber-mich-box-d {
  height: 50%;
  text-align: left;
  margin-left: 18%;
  width: 55%;
  overflow-y: auto;
  margin-bottom: 1%;
}
</style>