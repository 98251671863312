<template>
  <div class="kontakt-container">
    <div class="kontaktform">
      <div class="text-oben" v-if="!showText">
        <h3 style="">Deine Tattoanfrage</h3>

        Mit dem folgenden Anfrageformular stelle ich sicher, dass ich alle
        notwendigen Informationen für dein neues Tattoo erhalte, ohne dass etwas
        fehlt. Ich werde mich nach Erhalt in den kommenden Wochen mit zwei
        Terminvorschlägen bei dir melden und hierbei versuchen, deine
        Wunschzeiten zu berücksichtigen, was je nach Motiv und Größe jedoch nur
        bedingt möglich sein wird. <br />
        <br />
        Bei Neukunden und größeren Motiven behalte ich mir vor, ggf. eine
        Anzahlung zu nehmen, die dann mit dem Endpreis verrechnet wird. Solltest
        du deinen Termin nicht wahrnehmen können, muss deine Absage mindestens
        sieben Werktage vorher erfolgen, um deine Anzahlung zurück zu bekommen.
        Danach wird sie für den organisatorischen Mehraufwand meinerseits
        einbehalten. Im Krankheitsfall schauen wir individuell, wie wir das
        Problem lösen. Ich freue mich auf deine Anfrage und tolle Projekte, die
        wir zusammen gestalten. 🧡
      </div>
      <div class="formular">
        <div class="button-box">
          <button
            class="form-button"
            v-if="!showText"
            @click="showText = !showText"
          >
            Zur Anmeldung
          </button>
        </div>
        <form
          action="https://formsubmit.co/booking@gunstundtinte.de
"
          method="POST"
          enctype="multipart/form-data"
          v-if="showText"
        >
          <div class="form-group">
            <label for="VorUndNachname"
              >Vor und Nachname / first, last name:</label
            >
            <input
              autocomplete="true"
              type="text"
              id="VorUndNachname"
              name="name"
              required
            />
          </div>
          <div class="form-group">
            <label for="Email">Email:</label>
            <input
              autocomplete="true"
              type="text"
              id="Email"
              name="email"
              required
            />
          </div>
          <div class="form-group">
            <label for="HandyNummer">Handynummer / phone:</label>
            <input type="number" id="HandyNummer" name="nummer" required />
          </div>
          <div class="form-group">
            <label for="KoerperStelle">Körperstelle / bodypart:</label>
            <input
              type="text"
              id="KoerperStelle"
              name="Körperstelle"
              required
            />
          </div>
          <div class="form-group">
            <label for="MotivBeschreibung"
              >Motivbeschreibung / explanation:</label
            >
            <textarea
              name="beschreibung"
              id="MotivBeschreibung"
              rows="5"
            ></textarea>
            <div>
              <label for="foto">Beispielfoto / example (optional)</label>
              <input
                id="foto"
                type="file"
                name="attachment"
                accept="image/png, image/jpeg"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="ExtraAnmerkungen">Extra Anmerkungen / notes:</label>
            <textarea
              name="Extra Anmerkungen"
              id="ExtraAnmerkungen"
              rows="6"
              maxlength="300"
            ></textarea>
          </div>
          <div class="form-group">
            <label for="WunschTag">Wunschtag(e) / desired date:</label>
            <input type="text" id="WunschTag" name="Wunschtag(e)" />
          </div>
          <button type="submit" value="send">Senden</button>
        </form>
      </div>
    </div>
  </div>
</template>


<script>
import offWhite from '../assets/img/offWhite.jpg';
export default{
    data() {
        return {
            image: offWhite,
            showText: false
        }
    },
}
</script>


<style>
/* CSS */
textarea {
  resize: none;
}
.form-button {
  padding: 15px 25px;
  border: unset;
  border-radius: 15px;
  color: #203d36;
  z-index: 1;
  background-image: url("../assets/img/offWhite.jpg");
  background-size: cover;
  position: relative;
  font-weight: 1000;
  font-size: 17px;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  transition: all 250ms;
  overflow: hidden;
}

.form-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  border-radius: 15px;
  background-color: #203d36;
  z-index: -1;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  transition: all 250ms;
}

.form-button:hover {
  color: #fe5825;
}

.form-button:hover::before {
  width: 100%;
}
@media only screen and (min-width: 844px) {
  .TestForm {
    left: 0;
    position: absolute;
    font-family: monospace;
    font-size: large;
    color: #203d36;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-image: url("../assets/img/offWhite.jpg");
    background-size: cover;
    justify-content: center;
  }
  .kontaktform {
    height: 100%;
  }
  .form-button {
    margin-left: 15%;
  }
  .form-group {
    margin-top: 10px;
  }
  .form-group input,
  .form-group textarea {
    display: flex;
    padding: 5px;
    width: 50%;
    font-size: 8px;
    border: 1px solid rgba(128, 128, 128, 0.199);
    margin-top: 5px;
  }
  .text-oben {
    height: 40%;
    width: 90%;
    text-align: center;
    margin-left: 5%;
  }
  .formular {
    margin-top: 50px;
    margin-left: 25%;
    width: 100%;
    height: 85%;
    overflow-x: auto;
  }
}
@media only screen and (max-width: 844px) {
  .form-button {
    margin-top: 50px;
  }
  .kontakt-container {
    width: 100%;
  }
  .form-group input,
  .form-group textarea {
    display: flex;
    padding: 5px;
    width: 50%;
    font-size: 8px;
    border: 1px solid rgba(128, 128, 128, 0.199);
    margin-top: 5px;
  }
  .text-oben {
    height: 65%;
    width: 100%;
    text-align: center;
  }
  .formular {
    margin-left: 25%;
    width: 100%;
    height: 85%;
  }
  .TestForm {
    left: 0;
    position: absolute;
    font-family: monospace;
    font-size: large;
    color: #203d36;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-image: url("../assets/img/offWhite.jpg");
    background-size: cover;
    justify-content: center;
  }
  .kontaktform {
    height: 100%;
  }
  .form-group {
    margin-top: 10px;
  }
}
</style>