<template>
  <div class="portfolio-wrapper">
    <div class="text-lay-out">
      <h3 class="head-center">Hey!</h3>
      <div class="align-left">
        Da ich dieses Studio ganz neu aus dem Boden gestampft habe, wurden hier
        leider noch keine Projekte verwirklicht. <br />
        Aktuelle Arbeiten von mir findest du auf Instagram unter
        <a
          class="text-dec-none"
          href="https://www.instagram.com/theresart.tattoo/"
          target="blank"
          >@theresart.tattoo</a
        >
        <br />
        Das Portfolio hier wird in den kommenden Wochen aktualisiert. Danke für
        dein Verständnis!
      </div>
    </div>
  </div>
</template>

<script>
import portfolioBg from '../assets/img/portfolioBg.png'
export default {
    data() {
        return {
            image:portfolioBg
        }
    },
}
</script>

<style>
.portfolio-wrapper {
  font-family: monospace;
  font-size: 3vh;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../assets/img/portfolioBg.png");
  background-size: initial;
  display: flex;
  flex-direction: column;
}
.text-dec-none {
  text-decoration: none;
  color: antiquewhite;
}
.text-lay-out {
  width: 80%;

  align-self: center;
  margin-top: 15%;
}
.head-center {
  text-align: center;
}
.align-left {
  text-align: left;
}
@media only screen and (min-width: 600px) {
  .portfolio-wrapper {
    left: 0;
    position: absolute;
    width: 60%;
    height: 100%;
    background-image: url("../assets/img/portfolioBg.png");
    background-size: initial;
    display: flex;
    flex-direction: column;
  }
}
</style>