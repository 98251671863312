<template>
  <div class="datenschutz-wrapper"></div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
}
</script>

<style>
.datenschutz-wrapper {
  left: 0%;
  position: absolute;
  display: flex;
  flex-direction: column;

  height: 100vh;
  background-image: url("../assets/img/greenBg.jpg");
  background-size: cover;
  align-items: center;
  font-family: monospace-light;
  color: antiquewhite;
  font-size: 40%;
}
@media only screen and (min-width: 600px) {
  .datenschutz-wrapper {
    left: 0%;
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 100vh;
    background-image: url("../assets/img/greenBg.jpg");
    background-size: cover;
    align-items: center;
    font-family: monospace-light;
    color: antiquewhite;
    font-size: 70%;
  }
}
</style>