<template>
  <div class="atelier-wrapper">
    <div class="atelier-box-1">
      <div class="header-atelier">das Atelier.</div>
    </div>
    <div class="atelier-box-2">
      Gunst und Tinte <br />
      Zwei prägnante Worte, die all das zusammen fassen, was man wissen muss,
      betritt man das kleine Studio im Herzen von Petersberg. Ich habe versucht
      meine Liebe zu Details, Kunst und Obskuritäten in jeden rechten Winkel
      meiner Ladenfläche fließen zu lassen - es gibt viel zu entdecken und viel
      zu genießen. <br />
      Während wir zusammen Projekte planen oder du auf den Beginn deines Termins
      wartest, darfst du dich gerne umschauen. <br />
      Es wurde sehr viel geupcycled, mein großes Interesse an Nachhaltigkeit und
      Umweltfreundlichkeit spiegelt sich nicht nur in der Einrichtung wieder,
      auch die Materialien die ich während des Tätowierens verwende sind
      weitestgehend nachhaltig, plastikfrei und wiederverwendbar.
      Selbstverständlich im Rahmen aller hygienischen Bedingungen, die an ein
      Tattoostudio gestellt werden. Ich hoffe, dir gefällt die gemütliche
      Wohnzimmeratmosphäre genau so sehr wie mir und wir haben künftig eine
      entspannte gemeinsame, kreative Zeit in meinen eigenen vier Wänden.
    </div>
    <div class="atelier-box-3">
      <div class="atelier-image-container">
        <img class="atelier-logo" :src="logoAtelier" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import forestBack from '../assets/img/forestBack.jpg'
import logoAtelier from '../assets/img/Logo_Short.svg'
export default {
  data() {
    return {
      image: forestBack,
             logoAtelier
    }
  },
}
</script>

<style>
.atelier-wrapper {
  font-family: monospace;
  font-size: large;
  color: antiquewhite;
  display: flex;
  flex-direction: column;

  background-image: url("../assets/img/forestBack.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  left: 0;
  position: absolute;
}
.header-atelier {
  font-family: Casta;
  color: antiquewhite;
  font-size: 4em;
}
.atelier-box-1 {
  height: 20%;
}
.atelier-box-2 {
  height: 60%;
}
.atelier-box-3 {
  height: 20%;
}
.atelier-image-container {
  max-height: 50%;
  width: 99%;
}
.atelier-logo {
  width: 20%;
  height: 20%;
}
@media only screen and (min-width: 600px) {
  .atelier-wrapper {
    display: flex;

    font-family: monospace;
    font-size: 1.4em;
    background-image: url("../assets/img/forestBack.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 60%;
    left: 0;
    position: absolute;
  }
  .atelier-box-1 {
    font-size: large;
    margin-top: 5%;
    margin-left: 15%;
    width: 25%;
    height: 20%;
  }
  .header-atelier {
    font-family: Casta-light;
    font-size: 4em;
  }
  .atelier-box-2 {
    margin-top: 5%;
    margin-left: 15%;
    height: 60%;
    width: 80%;
  }
  .atelier-box-3 {
    height: 20%;
  }
  .atelier-logo {
    height: 15%;
    width: 15%;
  }
}
</style>